import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styled from 'styled-components';
import { colors, padding } from '@grnhse/seedling/lib/azalea/constants';

const TextAreaElem = styled.textarea`
  resize: none;

  // to prevent Mozilla and other browsers from making font 'monospace' or some other
  // arbitrarily defined browser style
  font-family: inherit;
  font-size: 14px;
  border: 1px solid ${colors.lightGrey};

  &&& {
    padding: ${padding.small};
  }
`;

// The !important is required to override styling in application.scss
const BlockTextAreaElem = styled(TextAreaElem)`
  display: block !important;
  width: 100% !important;
  box-sizing: border-box;
`;

/**
 * A very simple abstraction for `<textarea>` element that adds enable/disable logic
 * */
export default class TextArea extends React.Component {
  static propTypes = {
    block: PropTypes.bool,
    inputRef: PropTypes.func,
  };

  static defaultProps = {
    block: false,
    inputRef: () => {},
  };

  render() {
    // @ts-expect-error - TS2339 - Property 'disabled' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'. | TS2339 - Property 'block' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'. | TS2339 - Property 'className' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'. | TS2339 - Property 'inputRef' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
    const { disabled, block, className, inputRef, ...restProps } = this.props;
    const ComponentType = block ? BlockTextAreaElem : TextAreaElem;

    return (
      <ComponentType
        className={classNames(className, { disabled })}
        ref={inputRef}
        disabled={disabled}
        {...restProps}
      />
    );
  }
}
