/**
 * This is for when the normal `<Select /> component won't work or is a terrible UX
 * (i.e mobile devices.)
 *
 * */

import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { DownArrow } from '@grnhse/seedling/lib/azalea/icons';
import {
  colors,
  padding,
  fontSize,
} from '@grnhse/seedling/lib/azalea/constants';

export type DropdownProps = {
  onChange: (arg1: React.SyntheticEvent<HTMLSelectElement>) => unknown;
  value: unknown;
  children: ReactNode;
  name?: string;
  multiple?: boolean;
  required?: boolean;
  className?: string;
};

const Dropdown = ({
  onChange,
  value,
  children,
  className,
  multiple,
  ...otherSelectProps
}: DropdownProps) => {
  const classes = className
    ? className + ' dropdown-wrapper'
    : 'dropdown-wrapper';

  return (
    <DropdownSelectWrapper
      className={classNames(classes, { multiple: !!multiple })}
    >
      {!multiple && (
        <DropdownIndicator
          className="dropdown-indicator"
          width={fontSize.normal}
        />
      )}
      <DropdownSelect
        className="dropdown"
        onChange={onChange}
        // @ts-expect-error - TS2769 - No overload matches this call.
        value={value}
        multiple={!!multiple}
        {...otherSelectProps}
      >
        {children}
      </DropdownSelect>
    </DropdownSelectWrapper>
  );
};
export default Dropdown;

const DropdownSelectWrapper = styled.div`
  box-sizing: border-box;
  position: relative;

  border: 1px solid ${colors.lightGrey};
`;

const DropdownIndicator = styled(DownArrow)`
  position: absolute;
  height: 100%;
  width: 13px;
  right: 5px;

  z-index: 1;

  // ensure that the browser sends click events to the underlying element
  // and not this icon
  pointer-events: none;
`;

const DropdownSelect = styled.select`
  padding: ${padding.small};
  padding-right: ${padding.normal};
  width: 100%;
  height: 100%;

  // blow away browser defaults
  background-color: ${colors.white};
  outline: 0;
  border: 0;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
`;
