const checkWithPointerEventsApi = () => {
  const prefixes = ['webkit', 'ms', 'moz', 'o'];

  for (let i = 0; i < prefixes.length; i++) {
    if (window.navigator[prefixes[i] + 'MaxTouchPoints'] > 0) {
      return true;
    }
  }

  return window.navigator.maxTouchPoints > 0;
};

const checkWithTouchEventsApi = () => {
  return 'ontouchstart' in window;
};

const checkWithDeprecatedDocumentTouchApi = () => {
  return window.DocumentTouch && document instanceof window.DocumentTouch;
};

const checkWithUserAgent = () => {
  // These lists were pulled from vendor/cache/mobu-e151ac8d74b7/lib/mobu/detect_mobile.rb
  // This method is a fallback plan because in testing it is hard to emulate touch.
  const mobileUserAgents =
    /palm|blackberry|nokia|phone|midp|mobi|symbian|chtml|ericsson|minimo|audiovox|motorola|samsung|telit|upg1|windows ce|ucweb|astel|plucker|x320|x240|j2me|sgh|portable|sprint|docomo|kddi|softbank|android|mmp|pdxgw|netfront|xiino|vodafone|portalmmm|sagem|mot-|sie-|ipod|up\\.b|webos|amoi|novarra|cdm|alcatel|pocket|iphone|mobileexplorer|mobile/i;
  const tabletUserAgents =
    /ipad|android 3.0|xoom|sch-i800|playbook|tablet|kindle|honeycomb|gt-p1000/i;

  return (
    mobileUserAgents.test(navigator.userAgent) ||
    tabletUserAgents.test(navigator.userAgent)
  );
};

/**
 * Try to guess its a touch enabled device
 * */
export const canAssumeTouchDevice = () => {
  // does the device support any interfaces or api methods that would give away that
  // this is a mobile device?
  if (
    checkWithTouchEventsApi() ||
    checkWithPointerEventsApi() ||
    checkWithDeprecatedDocumentTouchApi() ||
    checkWithUserAgent()
  ) {
    return true;
  }
  return false;
};

export const canAssumeMobileDevice = () => {
  return window.innerWidth <= 500;
};
