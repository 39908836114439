import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { colors } from '@grnhse/seedling/lib/azalea/constants';

export const errorClass = '__form-field-error';

export class FormField extends React.Component {
  static defaultProps = {
    compact: false,
    large: false,
  };

  render() {
    const { className, error, children, notice, ...other } = this.props;
    const classes = classNames(className, { [errorClass]: !!error });

    return (
      <FormFieldContainer className={classes} {...other}>
        {children}
        {!error && notice && <NoticeWrapper>{notice}</NoticeWrapper>}
        {error && <FormError message={error} />}
      </FormFieldContainer>
    );
  }
}
const FormFieldContainer = styled.div`
  ${(props) => (props.compact ? '' : 'margin-bottom: 20px;')}
  ${(props) => (props.large ? 'margin-bottom: 24px;' : '')}

  position: relative;

  &.__form-field-error {
    input[type='text'],
    input[type='number'],
    textarea,
    [role='textbox'],
    .Select-control,
    .SingleDatePickerInput > .DateInput,
    .sl-dropdown__control {
      border-color: ${colors.darkRed} !important;
    }
  }
`;
FormFieldContainer.displayName = 'FormFieldContainer';

export const FormLabel = styled.label.attrs((props) => {
  return {
    'aria-required': props.required,
  };
})`
  display: block;
  color: ${colors.mediumGrey};
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;

  ${(props) =>
    props.required &&
    `
    &:after {
      color: ${colors.darkRed};
      content: '*';
      font-size: 12px;
      margin-left: 4px;
      line-height: normal;
    }`}
`;
FormLabel.displayName = 'FormLabel';

export class FormError extends React.PureComponent {
  render() {
    if (!this.props.message) {
      return null;
    }

    return (
      <ErrorMessage
        role="alert"
        className={errorClass}
        style={this.props.style}
        data-provides="form_error"
      >
        {this.props.message}
      </ErrorMessage>
    );
  }
}

const NoticeWrapper = styled.div`
  margin-top: 6px;
  display: flex;
  color: ${colors.darkGrey};
  font-size: 12px;
  font-weight: 400;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: ${colors.darkRed};
  font-size: 12px;
  font-weight: 400;
  white-space: pre-line;
`;
