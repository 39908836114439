/* global __DEV__: false */

import ProspectQuestion from 'events/models/prospect_question';
import type ProspectQuestionOption from 'events/models/prospect_question';

// it can take in an array of numbers or an array of string ids. This is to allow react
// select components as well as standard HTML select elements to utilize this method.
// TODO: Fix type annotations. This might require app code changes
export const deserializeDropdownValues = (
  question: ProspectQuestion,
  values: Array<string>
): Array<unknown> => {
  const options = question.question_options;

  if (!options) {
    return [];
  }

  return values.map((value) => {
    // the dropdowns have ids tied to them so find them and return the whole object
    const id = parseInt(value, 10);
    const parsedQuestionOption = Number.isNaN(id)
      ? null
      : options.filter((option) => option.id === id)[0];
    return parsedQuestionOption;
  });
};

/**
 * When it was originally serialized to an HTML element, the value got converted to string
 * so coerce it back to it's original type based on what type of question it was.
 * */
export const deserialize = (
  question: ProspectQuestion,
  value: string | Array<string>
): unknown => {
  if (question.isNumericQuestion()) {
    // @ts-expect-error - TS2345 - Argument of type 'string | string[]' is not assignable to parameter of type 'string'.
    return parseInt(value, 10);
  } else if (
    question.isSingleSelectQuestion() ||
    question.isMultiSelectQuestion()
  ) {
    if (typeof value === 'string') {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.error(
          'The value passed into the deserialize() method was passed a string instead of an array!'
        );
      }
      value = [value];
    }
    return deserializeDropdownValues(question, value);
  }
  // this will be of type phone number so let it return a full phone number object
  // everything else is already a string so just fall through
  else {
    return value;
  }
};
