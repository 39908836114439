import React from 'react';
import Select from 'shared/components/Select';
import Dropdown from 'shared/components/select_components/native_styled_dropdown';

import { deserializeDropdownValues } from 'events/prospects/deserialize_utils';
import { getSelectedDropdownValues } from 'shared/utils/formUtils';

export const ERROR_CLASS_NAME = 'error';
const SINGLE_SELECT_PLACEHOLDER_VALUE = '-1';

export default class WebOrMobileDropdown extends React.Component {
  onSelectDropdownChange = (selectedOptions) => {
    const { question, onChange } = this.props;

    if (!(selectedOptions instanceof Array)) {
      // user could have cleared his value by hitting backspace
      selectedOptions = selectedOptions === null ? [] : [selectedOptions];
    }

    const selectedOptionIds = selectedOptions.map((so) => so.value);
    onChange(deserializeDropdownValues(question, selectedOptionIds));
  };

  onNativeDropdownChange = (evt) => {
    const { question } = this.props;
    const options = question.question_options;

    if (!options) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.error(
          'onDropdownChange() called when question has no options.Must be a bug!'
        );
      }
      return;
    }

    // the dropdowns have ids tied to them so find them and return the whole object
    const optionsValues = getSelectedDropdownValues(evt.currentTarget);
    this.props.onChange(deserializeDropdownValues(question, optionsValues));
  };

  getDropdownOptionsForComponent(question) {
    const questionOptions = question.question_options || [];

    return questionOptions.map((qo) => {
      return { value: qo.id, label: qo.label || qo.name };
    });
  }

  renderDropdown() {
    const { question, touchFriendly, multiSelect, className, inputId } =
      this.props;
    const dropdownOptions = this.getDropdownOptionsForComponent(question);

    let defaultValue = multiSelect ? [] : SINGLE_SELECT_PLACEHOLDER_VALUE;
    let value = this.props.value ? this.props.value : defaultValue;

    // if it doesn't need to be touch friendly, use the Select component as it has a
    // better UX than the standard dropdowns especially for multi-select
    if (!touchFriendly) {
      return (
        <Select
          className={className}
          multi={multiSelect}
          onChange={this.onSelectDropdownChange}
          clearable={true}
          searchable={true}
          value={value}
          options={dropdownOptions}
          inputProps={{ id: inputId }}
        />
      );
    }

    return (
      <Dropdown
        className={className}
        multiple={multiSelect}
        onChange={this.onNativeDropdownChange}
        value={value}
        id={inputId}
      >
        {!multiSelect && <option value={SINGLE_SELECT_PLACEHOLDER_VALUE} />}
        {dropdownOptions.map((qo, idx) => (
          <option key={idx} value={qo.value}>
            {qo.label}
          </option>
        ))}
      </Dropdown>
    );
  }

  render() {
    return this.renderDropdown();
  }
}
