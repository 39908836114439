import $ from 'legacy/jquery';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import { Item } from '@grnhse/seedling/lib/birch/components/forms/dropdown/item';
// Submit an input field by pressing the enter key
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/DEPRECATED/components/form/layout'. 'app/webpack/javascripts/shared/DEPRECATED/components/form/layout.js' implicitly has an 'any' type.
import { errorClass } from 'shared/DEPRECATED/components/form/layout';

type NameOrText =
  | { name: string; text?: never }
  | { text: string; name?: never };

export type Option = NameOrText & {
  id?: string | number;
  children?: Option[];
};

export const onEnter =
  (fn: any) => (event: React.SyntheticEvent<HTMLInputElement>) => {
    // @ts-expect-error - TS2339 - Property 'key' does not exist on type 'SyntheticEvent<HTMLInputElement, Event>'.
    if (event.key === 'Enter') {
      fn(event);
    }
  };

export const isValidSubmit = (
  event: React.SyntheticEvent<HTMLInputElement>
) => {
  // TODO: use currentTarget instead of target (not changing now due to tests that rely on this structure)
  // @ts-expect-error - TS2339 - Property 'key' does not exist on type 'SyntheticEvent<HTMLInputElement, Event>'. | TS2339 - Property 'value' does not exist on type 'EventTarget'.
  return event.key === 'Enter' && event.target.value.trim().length > 0;
};

/**
 * Checks to see if the browser supports the input[type=datetime-local] field.
 *
 * It does so by creating a hidden input field with the specified type and
 * tries to check the type again. If it reverted to type=text, then we know its not supported
 * */
export const supportsDateTimeLocalInput = () => {
  const dateType = 'datetime-local';

  const dateInput = document.createElement('input');
  dateInput.setAttribute('type', dateType);

  return dateInput.type === dateType;
};

export const getSelectedDropdownValues = (
  elem: HTMLSelectElement
): Array<string> => {
  const options: Array<string> = [];

  // can't use a standard filter because HTMLOptionsCollection is not of type Array
  for (let i = 0; i < elem.options.length; i++) {
    const option = elem.options[i];

    if (option.selected) {
      options.push(option.value);
    }
  }

  return options;
};

export const getAsFormData = (obj: any): any => {
  const formData = new FormData();
  const keys = Object.keys(obj);

  keys.forEach((key) => {
    if (obj[key] instanceof window.FileList) {
      for (let i = 0; i < obj[key].length; i++) {
        formData.append(key, obj[key][i]);
      }
    } else if (
      typeof obj[key] === 'object' &&
      !(obj[key] instanceof window.File)
    ) {
      formData.append(key, JSON.stringify(obj[key]));
    } else {
      formData.append(key, obj[key]);
    }
  });

  return formData;
};

const buildFormData = (formData: FormData, data: any, parentKey: string) => {
  if (
    data !== null &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${isNaN(Number(key)) ? key : ''}]` : key
      );
    });
  } else {
    const value = data === null || data === undefined ? '' : data;

    formData.append(parentKey, value);
  }
};

export const jsonToFormData = (data: any) => {
  const formData = new FormData();

  buildFormData(formData, data, '');

  return formData;
};

/**
 * Finds the first form field within `rootElement` with a validation error (aria-invalid=true) and focuses it.
 * If none are found, it tries to search within the entire document.
 * If none are found at all, it fallbacks to our default errorClass for forms.
 *
 * @param rootElement
 */
export const focusOnFirstError = (rootElement: HTMLElement): void => {
  let firstElementWithValidationError =
    rootElement.querySelector('[aria-invalid=true]') ||
    document.querySelector('[aria-invalid=true]');

  if (!firstElementWithValidationError) {
    // We'd like to migrate away from this strategy and use more standardized methods, but
    // for backward-compatibility, we can fallback to this.
    const firstErrorWrapper = document.querySelector(`.${errorClass}`);

    firstElementWithValidationError =
      firstErrorWrapper &&
      (firstErrorWrapper.querySelector('input') ||
        firstErrorWrapper.querySelector('.tox-tinymce') ||
        firstErrorWrapper.querySelector('textarea') ||
        firstErrorWrapper.querySelector('label'));
  }

  if (firstElementWithValidationError) {
    const hasTabIndex =
      !!firstElementWithValidationError.getAttribute('tabIndex');

    if (!hasTabIndex) {
      firstElementWithValidationError.setAttribute('tabIndex', '99');
    }

    // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
    firstElementWithValidationError.focus();

    if (!hasTabIndex) {
      firstElementWithValidationError.removeAttribute('tabIndex');
    }
  }
};

export function scrollSubviewToFirstError(
  frameSelector: string,
  contentSelector: string
) {
  const errorFields = $(
    '.error, .field-error, .__form-field-error',
    frameSelector
  );

  if (errorFields.length > 0) {
    $('html, body').animate({
      scrollTop: $(frameSelector).first().offset().top,
    });
    const topOfContent = $(contentSelector, frameSelector).first().offset().top;
    const targetY = errorFields.first().offset().top - topOfContent;
    $(frameSelector).first().animate({ scrollTop: targetY });
  }
}

export function scrollWindowToFirstError() {
  const errorFields = $('.error, .field-error, .__form-field-error');

  if (errorFields.length > 0) {
    $('html, body').animate({
      scrollTop: errorFields.first().offset().top - $('#header').outerHeight(),
    });
  }
}

export const toDropdownOption = (x: Option | undefined | null): Item | null => {
  if (!x) {
    return null;
  }

  return { label: (x.text || x.name) as string, value: x.id as string };
};

export const toMultiDropdownOptions = (
  options: Array<Option | null | undefined> = []
): Item[] => {
  const arr = options.map((option) => {
    if (option?.children) {
      if (option?.id) {
        return [
          toDropdownOption(option),
          ...toMultiDropdownOptions(option.children),
        ];
      }

      return [...toMultiDropdownOptions(option.children)];
    }

    return toDropdownOption(option);
  });

  return compact(uniqBy(flatten(arr), 'label'));
};

export const toNestedDropdownOptions = (options: Option[] = []): Item[] => {
  const arr = options.map((option) => {
    if (option?.children) {
      if (option.id) {
        return {
          ...toDropdownOption(option),
          items: toNestedDropdownOptions(option.children),
        };
      } else {
        return {
          ...toDropdownOption(option),
          disabled: true,
          items: toNestedDropdownOptions(option.children),
        };
      }
    }

    return toDropdownOption(option);
  }) as Array<Item | null>;

  return compact(uniqBy(flatten(arr), 'label'));
};
