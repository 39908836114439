import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  SuccessFlash,
  ErrorFlash,
} from '@grnhse/seedling/lib/birch/components/flash';
import compact from 'lodash/compact';

const Container = styled.div`
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  gap: 2em;
  z-index: 1400; //birch modal is 1000, MUI modal is 1300
`;

const DEFAULT_NAME = 'Default';

type CustomSuccessFlash = `Flash.${string}.Success`;
type CustomErrorFlash = `Flash.${string}.Error`;

declare global {
  interface WindowEventMap {
    [key: CustomSuccessFlash]: CustomEvent;
    [key: CustomErrorFlash]: CustomEvent;
  }
}

function flashSuccess(message: string, name = DEFAULT_NAME) {
  window.dispatchEvent(
    new CustomEvent(`Flash.${name}.Success`, {
      detail: {
        message,
      },
    })
  );
}

function flashError(message: string, name = DEFAULT_NAME) {
  window.dispatchEvent(
    new CustomEvent(`Flash.${name}.Error`, {
      detail: {
        message,
      },
    })
  );
}

interface FlashEvent {
  message: string;
}

interface FlashManagerProps {
  error?: string;
  success?: string;
  name?: string;
}

const FlashManager = (props: FlashManagerProps) => {
  const { error, success, name = DEFAULT_NAME } = props;

  const [successMessages, setSuccessMessages] = useState(compact([success]));
  const [errorMessages, setErrorMessages] = useState(compact([error]));

  useEffect(() => {
    const handleSuccessEvent = (event: CustomEvent<FlashEvent>) => {
      setSuccessMessages([...successMessages, event.detail.message]);
    };

    window.addEventListener(`Flash.${name}.Success`, handleSuccessEvent);

    return () => {
      window.removeEventListener(`Flash.${name}.Success`, handleSuccessEvent);
    };
  });

  useEffect(() => {
    const handleErrorEvent = (event: CustomEvent<FlashEvent>) => {
      setErrorMessages([...errorMessages, event.detail.message]);
    };

    window.addEventListener(`Flash.${name}.Error`, handleErrorEvent);

    return () => {
      window.removeEventListener(`Flash.${name}.Error`, handleErrorEvent);
    };
  });

  return (
    <Container data-provides={`${name}-flash-container`}>
      {successMessages.map((message, index) => (
        <SuccessFlash key={index} message={message} />
      ))}
      {errorMessages.map((message, index) => (
        <ErrorFlash key={index} message={message} />
      ))}
    </Container>
  );
};

export { FlashManager, flashSuccess, flashError };
