import React from 'react';
import ReactSelectPlus from 'react-select-plus';
import PropTypes from 'prop-types';
import createFilterOptions from 'react-select-fast-filter-options';
import { createGlobalStyle } from 'styled-components';
import { Chip } from '@grnhse/seedling/lib/azalea/components/chip';
import { standardRequestWithFlash } from 'shared/utils/request';
import { withLocalePrefix } from 'shared/utils/translation';

const t = withLocalePrefix('select');

import 'shared/styles/select.css';
import { fixKeys } from './select_components/utils';

class Select extends React.PureComponent {
  static defaultProps = {
    clearable: false,
  };

  buildStandardProps() {
    return {
      filterOptions: createFilterOptions({
        options: fixKeys(this.props.options),
      }),
      noResultsText: t('noResultsText'),
      loadingPlaceholder: t('loadingMessage'),
      searchPromptText: t('searchPromptText'),
      placeholder: t('placeholder'),
    };
  }

  newProps() {
    const props = Object.assign({}, this.buildStandardProps(), this.props);

    if (this.props.multi) {
      props.valueComponent = this.renderOptionsAsChips;
    }

    return props;
  }

  renderOptionsAsChips = (config) => {
    // we only care about custom rendering the "chips" or multi-select values
    // if not, use the default renderer

    // you *need* to bind them to onMouseDown
    // because when you click on an item
    // react-select opens the menu and kills the event propagation
    const onMouseDown = (evt) => {
      // prevent react-select from opening the menu on click
      evt.stopPropagation();

      if (!config.disabled) {
        if (config.onClick) {
          config.onClick(evt);
        }
        if (config.onRemove) {
          config.onRemove(config.value);
        }
      }
    };

    // support `labelKey` and standard config.value
    const label = config.value[this.props.labelKey] || config.value.label;
    // clearableValue is a standard prop in react-select-plus, this makes our chip components respect it
    const clearable = config.value.clearableValue !== false;

    return (
      <Chip
        key={config.id}
        status="default"
        text={label}
        onRemove={onMouseDown}
        readonly={!clearable}
      />
    );
  };

  render() {
    return (
      <>
        <GlobalStyle />
        <ReactSelectPlus {...this.newProps()} />
      </>
    );
  }
}

Select.Async = class extends Select {
  constructor(props) {
    super(props);
  }

  static displayName = 'AsyncSelect';

  static defaultProps = {
    clearable: false,
    onBlurResetsInput: true,
    noResultsText: t('noResultsText'),
    loadingPlaceholder: t('loadingMessage'),
    searchPromptText: t('searchPromptText'),
  };

  onBlur = (evt) => {
    const { onBlurResetsInput } = this._select.props;

    /**
     * This "fix" exists to fix a caching problem in react-select.
     *
     * TL;DR: When `onBlurResetsInput` prop is true, then the input value should be
     *        cleared onBlur. When the user navigates back to it, the dropdown values should
     *        be relevant to the empty input query. Instead the old cached results are shown
     *        even if `cache` prop is set to false.
     *
     *        This fix should be explicitely removed when the fix is merged.
     *
     * @see https://github.com/JedWatson/react-select/pull/1305
     * */
    // BEGIN FIX
    if (this.props.onBlur) {
      this.props.onBlur(evt);
    }

    if (onBlurResetsInput) {
      this._select.loadOptions('');
    }
    // END FIX
  };

  // manually reload options with empty search term
  loadOptions = () => {
    this._select.loadOptions('');
  };

  // manually reset value
  resetValue = () => {
    this._select.select.setValue(this._select.select.getResetValue());
  };

  newProps() {
    const props = Object.assign({}, this.props);

    if (this.props.multi) {
      props.valueComponent = this.renderOptionsAsChips;
    }

    return props;
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <ReactSelectPlus.Async
          ref={(selectRef) => {
            this._select = selectRef;
          }}
          onBlur={this.onBlur}
          valueComponent={this.renderOptionValue}
          {...this.newProps()}
        />
      </>
    );
  }
};

Select.LazyLoad = class extends React.PureComponent {
  static displayName = 'LazyLoadSelect';

  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    path: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.setState({ options: [], isLoading: false });
    }
  }

  getOptions = () => {
    if (this.state.options.length === 0 && !this.state.isLoading) {
      this.setState({ isLoading: true });

      standardRequestWithFlash.get(this.props.path).then((response) => {
        this.setState({
          options: response.data.results,
          isLoading: false,
        });
      });
    }
  };

  render() {
    return (
      <>
        <GlobalStyle />
        <Select
          isLoading={this.state.isLoading}
          options={this.state.options}
          searchable={false}
          onOpen={this.getOptions}
          {...this.props}
        />
      </>
    );
  }
};

export default Select;

const GlobalStyle = createGlobalStyle`
  .Select-option.is-disabled {
    color: #9b9b9b;
  }

  .Select-menu-outer {
    z-index: 5;
  }

  .Select--multi .Select-multi-value-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: start;

    .Select-input {
      height: auto;
    }
  }

  .Select-clear-zone {
    background-color: inherit;
    padding: 0 5px;
  }

  .Select-arrow-zone {
    background-color: inherit;
  }

  .Select-value {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }

  .Select-placeholder {
    width: 95%;
  }
`;
