// note that this function mutates directly!
const fixKeys = (options) => {
  if (options) {
    options.forEach((option) => {
      if (option.hasOwnProperty('text') || option.hasOwnProperty('name')) {
        option.value = option.id;
        option.label = option.text || option.name;
        option.options = option.children;

        if (option.options && option.options.length > 0) {
          fixKeys(option.options);
        }
      }
    });

    return options;
  }
};

const appendSuffix = (options, suffixKeyName) => {
  if (options) {
    options.forEach((option) => {
      if (option.hasOwnProperty(suffixKeyName)) {
        option.label = `${option.label} (${option[suffixKeyName]})`;

        if (option.options && option.options.length > 0) {
          appendSuffix(option.options, suffixKeyName);
        }
      }
    });

    return options;
  }
};

function matchesFilter(option, filter) {
  return (
    option.label && option.label.toLowerCase().includes(filter.toLowerCase())
  );
}

const filterOptions = (options, filter, currentValues) => {
  if (!options) {
    return [];
  }

  return options.filter((option) => {
    const matchingChildren = filterOptions(
      option.options,
      filter,
      currentValues
    );

    const hasMatchingChildren = matchingChildren && matchingChildren.length > 0;

    if (hasMatchingChildren) {
      option.options = matchingChildren;
    }

    const currentlySelected = currentValues
      ? currentValues.some((cv) => cv.value === option.value)
      : false;

    return (
      (matchesFilter(option, filter) || hasMatchingChildren) &&
      !currentlySelected
    );
  });
};

export { fixKeys, appendSuffix, filterOptions };
